







































































































































/**
 * 关于tags组件的说明
 * @displayName tags组件
 */
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { getAllTags } from '@/utils/getTags'
import Clickoutside from '@/utils/clickoutside'
import _ from 'lodash'
import { ElCascaderPanel } from 'element-ui/types/cascader-panel'

@Component({
  components: {},
  directives: { Clickoutside }
})
export default class HelloWorld extends Vue {
  @Ref('panel') readonly panel: ElCascaderPanel
  /**
   * 选中项绑定值value
   * @values {tagIds:[],tagTypes:[],strange, true}
   */
  @Prop({ required: false }) private value: {
    tagIds: string[]
    tagTypes: string[]
    strange: boolean
  }
  /**
   * 组件是否可用
   * @values true、false
   */
  @Prop({ required: false, default: false }) isDisabled: boolean
  /**
   * 弹出框的位置
   * @values 在输入框的上方top-start、下方bottom-start
   */
  @Prop({ required: false, default: 'top-start' }) placement: string

  /**
   * 开启是否懒加载
   * @values true false
   */
  @Prop({ required: false, default: false }) lazy: boolean

  /**
   * 开启懒加载后 每种类型(vip, white, black)默认展示的数据个数 需配合lazy一起使用
   * @values 20
   */
  @Prop({ required: false, default: 20 }) loadSize: number

  selectType = 'tagType'
  separator = '/'
  showAllLevels = false
  placeholder = '请选择身份'
  readonly = false
  dropDownVisible = false
  presentTags: any[] = []
  suggestions = []
  inputValue: any = ''
  filterable = false
  presentText: string | null = ''
  filtering = false
  inputHover = false
  tagTypes: any[] = [
    { label: 'VIP', value: 'vip', disabled: false },
    { label: '白名单', value: 'white', disabled: false },
    { label: '黑名单', value: 'black', disabled: false }
  ]
  radio = 0
  checkList: any[] = []
  tagOptions: any[] = [] // 所有的标签
  currentOptions: any[] = [] // 当前展示的标签，主要针对开启懒加载后
  values: any[] = []
  tagList: any[] = []
  debounce = 300
  filterHandler: null | any = null
  pressDeleteCount = 0
  selectedOptions: any[] = []
  cascaderValue: {
    tagIds: string[]
    tagTypes: string[]
    strange: boolean
  } = { tagIds: [], tagTypes: [], strange: true }
  checkedStrange = true
  config = {
    multiple: true,
    checkStrictly: false
  }
  currentType = ''

  get leafOnly() {
    return !this.config.checkStrictly
  }
  get clearBtnVisible() {
    // 是否显示清空按钮
    if (this.isDisabled || this.filtering || !this.inputHover) {
      return false
    }
    return this.presentTags.length
  }

  @Watch('values')
  watchvalues(val: any) {
    this.valueChange('tagId', val)
  }
  @Watch('checkList')
  watchCheckList(val: any) {
    this.valueChange('tagType', val)
  }
  @Watch('radio')
  watchRadio(val: number) {
    if (val) {
      this.valueChange('tagId', this.values)
      this.setDisabled(this.currentOptions, false)
    } else {
      this.valueChange('tagType', this.checkList)
      this.setDisabled(this.currentOptions, true)
    }
  }
  @Watch('value')
  async watchValue() {
    if (this.tagList.length === 0) this.tagList = await getAllTags()
    if (this.tagOptions.length === 0) this.tagOptions = await this.getGroupData()
    this.initLazyTag()
    this.initDefaultValue()
  }
  @Watch('checkedStrange')
  watchStrange(val: boolean) {
    Object.assign(this.cascaderValue, { strange: val })
    this.$emit('change', { identity: this.cascaderValue })
  }
  async mounted() {
    this.filterHandler = _.debounce(() => {
      const { inputValue, selectType } = this
      if (!inputValue && selectType === 'tagType') {
        this.filtering = false
        return
      }
      this.getSuggestions()
    }, this.debounce)
  }

  private initDefaultValue() {
    if (!this.value) return

    const ops = this.currentOptions
    const { tagTypes, tagIds, strange } = this.value
    const result: any[][] = []
    this.checkedStrange = strange
    if (tagTypes && tagTypes.length) {
      this.radio = 0
      this.selectType = 'tagType'
      this.setDisabled(this.currentOptions, true)
      this.checkList = tagTypes
    }

    for (const op of ops) {
      const childrens = op.children || []
      if (tagIds && tagIds.length) {
        this.radio = 1
        for (const children of childrens) {
          const value = children.value
          if (tagIds.includes(value)) {
            result.push([op.value, value])
          }
        }
      }
    }

    this.values = result
  }

  // 开启懒加载时候，初始化的标签数据
  initLazyTag() {
    if (!this.lazy) {
      this.currentOptions = this.tagOptions
      return
    }
    this.currentOptions = []
    const options = this.tagOptions || []
    for (const ele of options) {
      let children = []
      if (ele.children && ele.children.length > this.loadSize) {
        children = ele.children.slice(0, this.loadSize)
      } else if (ele.children) {
        children = ele.children
      }
      this.currentOptions.push({
        ...ele,
        children
      })
    }
  }

  scrollCallback(el: any) {
    return () => {
      const scrollTop = el.scrollTop
      const clientHeight = el.clientHeight
      const scrollHeight = el.scrollHeight

      // 若scrollTop + clientHeight === scrollHeight 则说明滚动条到达了底部
      if (scrollTop + clientHeight === scrollHeight) {
        const tmp: any = []
        // 当前类型下所有的选项
        const allOptions = this.tagOptions.filter(
          (options: any) => options.value === this.currentType
        )[0]?.children

        this.currentOptions.forEach((element: any) => {
          if (element.value === this.currentType) {
            const optionLen = element.children.length - 1
            const allOptionsLen = allOptions.length - 1

            if (optionLen >= allOptionsLen) {
              return
            }

            const loadsize = optionLen + this.loadSize

            const size = loadsize > allOptionsLen ? allOptionsLen : loadsize
            for (let i = optionLen; i <= size; i++) {
              tmp.push(allOptions[i])
            }
            element.children.push(...tmp)
          }
        })

        setTimeout(() => {
          el.scrollTop = scrollTop
        })
      }
    }
  }

  handleChange(value: any) {
    if (!this.lazy) {
      return
    }
    this.$nextTick(() => {
      const panel = document.querySelectorAll('.el-cascader-menu__wrap.el-scrollbar__wrap')
      const length = panel.length
      const currentPanel = panel[length - 1]
      this.currentType = value[0]

      if (currentPanel) {
        currentPanel.addEventListener('scroll', this.scrollCallback(currentPanel))
      }
    })
  }

  valueChange(type: string, val: any[]) {
    this.selectType = type
    let tagIds: any[] = [],
      tagTypes: any[] = []
    if (type === 'tagId') {
      tagIds = val.map(v => v[1])
    } else {
      tagTypes = val
    }
    Object.assign(this.cascaderValue, { tagIds, tagTypes, strange: this.checkedStrange })
    this.computePresentContent()
    /**
     * 当选中节点变化时触发
     * @event change
     * @property 选中节点的值
     */
    this.$emit('change', { identity: this.cascaderValue })
  }
  getSuggestions() {
    if (this.selectType === 'tagType') {
      this.suggestions = []
      return
    }
    const suggestions: any = this.tagList.filter(ele => ele.name.includes(this.inputValue))

    this.presentTags.forEach(tag => {
      tag.hitState = false
    })

    this.filtering = true
    this.suggestions = suggestions.map((e: any) => {
      e.checked = false
      return e
    })
  }
  setDisabled(data: any, isDisabled: boolean) {
    data.forEach((item: any) => {
      item.disabled = isDisabled
      if (item.children) {
        this.setDisabled(item.children, isDisabled)
      }
    })
  }
  getGroupData() {
    return this.tagTypes.map(item => {
      const arr = this.tagList.filter(i => i.type === item.value)
      arr.map(i => {
        const { name, id } = i
        i.label = name
        i.value = id
      })
      if (arr.length > 0) item.children = arr
      return item
    })
  }
  handleInput(val: any, event: any) {
    !this.dropDownVisible && this.toggleDropDownVisible(true)

    if (event && event.isComposing) return
    if (val) {
      this.filterHandler()
    } else {
      this.filtering = false
    }
  }
  handleDelete() {
    const { inputValue, pressDeleteCount, presentTags } = this
    const lastIndex = presentTags.length - 1
    const lastTag = presentTags[lastIndex]
    this.pressDeleteCount = inputValue ? 0 : pressDeleteCount + 1
    if (!lastTag) return
    if (this.pressDeleteCount) {
      if (lastTag.hitState) {
        this.deleteTag(lastIndex, null)
      } else {
        lastTag.hitState = true
      }
    }
  }
  handleSuggestionClick(tag: any) {
    if (tag.checked) {
      const index = this.values.findIndex(e => e[1] == tag.id)
      this.values.splice(index, 1)
    } else {
      this.values.push([tag.type, tag.id])
    }
    tag.checked = !tag.checked
    this.panel.calculateMultiCheckedValue()
  }
  handleClear() {
    this.presentText = ''
    this.checkList = []
    this.panel.clearCheckedNodes()
  }
  isDef(val: any) {
    return val !== undefined && val !== null
  }
  toggleDropDownVisible(visible: boolean | undefined) {
    if (this.isDisabled) return

    const { dropDownVisible } = this
    visible = visible !== undefined && visible !== null ? visible : !dropDownVisible
    if (visible !== dropDownVisible) {
      this.dropDownVisible = visible
      if (visible) {
        this.$nextTick(() => {
          this.panel.scrollIntoView()
        })
      }
      this.filterable = visible
      /**
       * 下拉框出现/隐藏时触发
       * @event visible-change
       * @property {Boolean}  出现则为 true，隐藏则为 false
       */
      this.$emit('visibleChange', visible)
    }
  }
  deleteTag(index: number, tag: AnyObj | null) {
    const { checkList, values } = this
    if (tag) {
      const list: any[] = this.suggestions.filter((i: AnyObj) => i.name === tag.text)
      if (list.length > 0) list[0].checked = false
    }
    if (this.selectType === 'tagType') {
      this.checkList = checkList.filter((n, i) => i !== index)
    } else {
      this.values = values.filter((n, i) => i !== index)
    }
  }

  handleDropdownLeave() {
    this.filtering = false
    this.inputValue = this.presentText
  }
  computePresentContent() {
    this.$nextTick(() => {
      this.computePresentTags()
      this.presentText = this.presentTags.length ? ' ' : null
    })
  }
  computePresentTags() {
    const tags = []
    if (this.selectType === 'tagType') {
      const list = this.checkList
      const tagtype = this.tagTypes.map(i => i.value)
      if (tagtype.every(i => list.includes(i))) {
        tags.push({
          key: -1,
          text: '全部身份',
          closable: false,
          allTag: true
        })
      } else {
        const tag = {
          closable: !this.isDisabled,
          hitState: false,
          key: 1
        }
        const label = this.checkList.map(item => {
          return this.tagTypes.find(i => i.value === item)?.label
        })
        if (list.length) {
          const [first, ...rest] = label
          const restCount = rest.length
          tags.push(Object.assign(tag, { text: first }))
          if (restCount) {
            tags.push({
              key: -1,
              text: `+ ${restCount}`,
              closable: false
            })
          }
        }
      }
    } else {
      const { isDisabled, leafOnly, showAllLevels, separator } = this
      const checkedNodes = this.getCheckedNodes(leafOnly)

      const genTag = (node: any) => ({
        node,
        key: node.uid,
        text: node.getText(showAllLevels, separator),
        hitState: false,
        closable: !isDisabled && !node.isDisabled
      })

      if (checkedNodes.length) {
        const [first, ...rest] = checkedNodes
        const restCount = rest.length
        tags.push(genTag(first))

        if (restCount) {
          tags.push({
            key: -1,
            text: `+ ${restCount}`,
            closable: false
          })
        }
      }
    }
    this.presentTags = tags
  }
  getCheckedNodes(leafOnly: boolean) {
    return this.panel.getCheckedNodes(leafOnly)
  }
}
