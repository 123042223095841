






















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { getHeight, getWidth } from '@/utils/dom'
import { secure } from '@/utils/secureRules'

@Component
export default class PersonInfo extends Vue {
  @Prop({ required: true, default: {} }) personInfo: AnyObj

  position = '???'
  name = '???'
  sexMap: Obj<string, string> = {
    female: '女',
    male: '男'
  }
  sex = ''
  age = ''
  identity = ''

  get maskName() {
    return this.name && this.name !== '???' ? secure.fullName(this.name) : '???'
  }

  get boxWidth() {
    const windowWidth = window.innerWidth
    return (windowWidth / 1920) * 304 - 20
  }

  get boxHeight() {
    const windowWidth = window.innerWidth
    return (windowWidth / 1920) * 415 - 20
  }

  mounted() {
    this.$nextTick(() => {
      const box = document.getElementsByClassName('head-box')[0] as HTMLElement
      const boxWidth = getWidth(box)
      const boxHeight = getHeight(box)
      const newImg = document.getElementsByClassName('head-portrait')[0] as HTMLElement
      newImg.onload = () => {
        const newImgHeight = getHeight(newImg)
        const newImgWeight = getWidth(newImg)

        if (newImgWeight >= newImgHeight) {
          newImg.style.height = boxHeight + 'px'
        } else {
          newImg.style.width = boxWidth + 'px'
        }
      }
    })

    setTimeout(() => {
      this.position = this.personInfo.position
      this.name = this.personInfo.name
      this.sex = this.sexMap[this.personInfo.gender]
      this.age = this.personInfo.age
      this.identity = '身份未知'
    }, 4000)
  }
}
