













import { Vue, Component, Watch } from 'vue-property-decorator'
import Stats from './stats.module'
import PhotoWall from '@/components/dashboard/PhotoWall/index.vue'
import editDialog from './components/EditDialog/index.vue'

@Component({
  name: 'cubePage',
  components: { PhotoWall, editDialog }
})
export default class CubePage extends Vue {
  private isShow = false
  private stats: any = null
  private isComputed = false

  get personDetail() {
    return this.$store.state.dashboard.personMsg
  }

  @Watch('isComputed')
  watchIsComputed() {
    this.getComputed()
  }

  activated() {
    this.isShow = true
    window.vue = this
  }

  private showSet(isRender: boolean) {
    if (isRender) {
      this.isShow = false
      setTimeout(() => {
        this.isShow = true
      }, 100)
    } else {
      ;(this.$refs.wallNode as any).updateDate()
    }
  }
  private getComputed() {
    //@ts-ignore
    this.stats = new Stats()
    this.loop()
  }
  private rAf() {
    return (
      window.requestAnimationFrame(this.loop) ||
      window.webkitRequestAnimationFrame(this.loop) ||
      function (callback: Function) {
        window.setTimeout(callback, 1000 / 60)
      }
    )
  }
  private loop() {
    if (this.isComputed) {
      this.stats.update()
      this.rAf()
    }
  }

  deactivated() {
    this.isShow = false
  }
}
