






































































































































































































































































































































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import TagsCascader from '../Cascader/index.vue'
import { EventBus } from '@/utils/eventBus'
import { judgeValue } from '@/utils/index'
import { ElForm } from 'element-ui/types/form'

@Component({ name: 'editDialog', components: { TagsCascader } })
export default class EditDialog extends Vue {
  @Ref() readonly tagNode: TagsCascader
  @Ref() readonly ruleForm: ElForm
  private rules: any = {
    title: [{ min: 1, max: 20, message: '长度不超过20个字符', trigger: 'blur' }],
    subtitle: [{ min: 1, max: 20, message: '长度不超过20个字符', trigger: 'blur' }],
    left: [{ min: 1, max: 20, message: '长度不超过20个字符', trigger: 'blur' }],
    right: [{ min: 1, max: 20, message: '长度不超过20个字符', trigger: 'blur' }],
    capacity: [
      { required: true, message: '请输入容量上限', trigger: 'blur' },
      {
        validator: (rule: any, value: any, callback: Function) => {
          if (value) {
            if (!(/^[1-9]{1,}[\d]*$/.test(value) && value >= 50 && value <= 1000)) {
              callback(new Error('请输入50~1000的正整数'))
            } else {
              callback()
            }
          } else {
            callback(new Error('请输入容量上限'))
          }
        },
        trigger: 'blur'
      }
    ],
    areaIds: [{ required: true, message: '请选择区域', trigger: 'blur' }]
  }
  private form: AnyObj = {
    title: '',
    subtitle: '',
    left: '',
    right: '',
    capacity: 300,
    logo: '',
    background: '',
    areaIds: [],
    identity: {}
  }
  private upload = ''
  private isShow1 = false
  private isShow2 = false
  private current: number | null = null
  private logoUrl = ''
  private wallBg = ''
  private defaultBg = require('@/assets/images/cube/default-bg.png')
  private showFlag = false
  private loading = false
  private identity: { tagIds: string[]; tagTypes: string[]; strange: boolean } = {
    tagIds: [],
    tagTypes: ['vip', 'white', 'black'],
    strange: true
  }

  created() {
    EventBus.$on('setDialog', () => {
      this.showFlag = true
    })
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  @Watch('showFlag')
  watchShowFlag(param: boolean) {
    if (param === true) {
      this.$nextTick(() => {
        this.ruleForm.resetFields()
        this.getconfigcube()
      })
    }
  }
  deactivated() {
    this.showFlag = false
  }
  updated() {
    this.$nextTick(() => {
      setTimeout(() => {
        const newImg: any = this.$refs.avatar
        if (newImg !== undefined) {
          if (newImg.clientWidth >= newImg.clientHeight) {
            newImg.style.width = 121 + 'px'
          } else {
            newImg.style.height = 121 + 'px'
          }
        }
      }, 1000)
    })
  }
  private iconShow(index: number) {
    this.current = index
  }
  private iconHidden() {
    this.current = null
  }
  private deleteOldPhoto(type: string) {
    if (type === 'logo') {
      this.form.logo = ''
      this.logoUrl = ''
    }
    if (type === 'background') {
      this.form.background = ''
      this.wallBg = ''
    }
    ;(this.$refs.inputFile as Vue).value = ''
  }
  private cancelDialog() {
    this.showFlag = false
  }
  blurSelect() {
    this.$nextTick(() => {
      ;(this.$refs.ruleForm as Vue).validateField('areaIds')
    })
  }

  private async onSubmit() {
    ;(this.$refs.ruleForm as Vue).validate(async (valid: any) => {
      if (valid) {
        this.loading = true

        this.form.capacity = Number(this.form.capacity)
        judgeValue(this.form)
        try {
          const { data } = await this.$api.base.submitConfigCube(this.form)
          if (data.code === 0) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            let isRender = false
            const preCapacity = sessionStorage.getItem('capacity')
            if (!preCapacity || (preCapacity && Number(preCapacity) !== this.form.capacity)) {
              isRender = true
            }
            this.$emit('show', isRender)
            this.showFlag = false
          }
        } catch (error) {
          this.$message({
            message: '提交失败',
            type: 'warning'
          })

          this.loading = false
        }
      }
    })
  }
  // 打开图片上传
  private uploadHeadImg(type: any) {
    this.upload = type
    const hiddenInput: any = this.$el.querySelector('.hiddenInput')
    if (hiddenInput) {
      hiddenInput.click()
    }
  }
  // 将头像显示
  private async handleFile(e: any) {
    const $target = e.target || e.srcElement
    const file = $target.files && $target.files[0]
    if (this.beforeAvatarUpload(file)) {
      const uploadForm: any = this.$refs.uploadForm
      const inputFile: any = this.$refs.inputFile as unknown
      const formData = new FormData(uploadForm) //初始化时将form Dom对象传入
      formData.append('imagefile', inputFile) //将imagefile键追加进去，值为input-file的dom对象，否则服务端无法获取file
      const { data } = await this.$api.base.uploadImage(formData)
      if (data.code === 0) {
        if (this.upload === 'head') {
          this.form.logo = data.data.saveUrl
          this.logoUrl = data.data.url
        } else {
          this.form.background = data.data.saveUrl
          this.wallBg = data.data.url
        }
      }
    }
  }
  private beforeAvatarUpload(file: any) {
    const isLt5M = file.size / 1024 / 1024 < 5
    if (this.upload === 'head') {
      if (isLt5M) {
        this.isShow1 = false
      } else {
        this.isShow1 = true
      }
    } else {
      if (isLt5M) {
        this.isShow2 = false
      } else {
        this.isShow2 = true
      }
    }
    return isLt5M
  }

  private getCascaderTags(val: any) {
    Object.assign(this.form, val)
  }

  private async getconfigcube() {
    const resp = await this.$api.base.getConfigCube()
    if (resp.data && resp.data.data) {
      this.form = resp.data.data
      this.wallBg = resp.data.data.backgroundUrl

      delete this.form.createdAt
      delete this.form.updatedAt
      delete this.form.backgroundUrl
      if (!this.form.areaIds) this.form.areaIds = [this.root]
      if (this.form.identity) {
        const { tagIds, tagTypes } = this.form.identity
        if (!tagIds && !tagTypes) {
          this.identity = { tagIds: [], tagTypes: ['vip', 'white', 'black'], strange: true }
        } else {
          this.identity = this.form.identity
        }
      }
      if (this.form.capacity === null) {
        this.form.capacity = 300
      }
      sessionStorage.setItem('capacity', this.form.capacity)
    } else {
      this.form.areaIds = [this.root]
      this.identity = { tagIds: [], tagTypes: ['vip', 'white', 'black'], strange: true }
    }
  }
}
