







































/* eslint-disable */
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import PersonInfo from '@/components/dashboard/PersonInfo/index.vue'
import { compareTagOrArea, getAttr } from '@/utils'
import { EventBus } from '@/utils/eventBus'
import moment from 'moment'
import fastdom from 'fastdom'
import store, { actionTypes } from '@/store'
import _ from 'lodash'
import TWEEN from '@tweenjs/tween.js'

let routeName: string | null | undefined = null

const router = store.state.app?.$router
if (router) {
  router.afterEach((to, from) => {
    routeName = from.name
  })
}

@Component({ components: { PersonInfo } })
export default class PersonVisit extends Vue {
  @Prop({ required: true }) person: AnyObj

  @Ref('videoPlay') videoPlay: HTMLVideoElement

  personArray: AnyObj[] = []
  CurPersonNum = 0
  camera: AnyObj = {}
  scene: AnyObj = {}
  renderer: AnyObj = {}
  controls: AnyObj = {}
  objects: AnyObj[] = []
  targets: Obj<string, AnyObj[]> = { table: [], sphere: [], helix: [], grid: [] }
  personList: AnyObj[] = []
  isMouse = false
  personInformation: AnyObj = {}
  showDialog = false
  msgList: AnyObj[] = []
  isShowIcon = false
  identity: { tagIds: string[]; tagTypes: string[]; strange: boolean } = {
    tagIds: [],
    tagTypes: ['vip', 'white', 'black'],
    strange: true
  }
  logo = ''
  wallBg = ''
  areas: string[] = []
  title = ''
  subtitle = ''
  capacity = 300
  isSet = false
  setInter = 0
  right = ''
  left = ''
  setVideoWidth = false
  isShow = true
  time = 1000
  animateFrame: number | null = null
  configData = {}

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  @Watch('showDialog')
  watchShowDialog() {
    if (this.showDialog) {
      if (this.videoPlay) this.videoPlay.currentTime = 0
    }
  }

  @Watch('person')
  watchPerson() {
    const container = document.getElementById('container')
    if (container) {
      const existPerson = this.personList.find(l => l.id === this.person.id)
      if (existPerson) {
        Object.assign(existPerson, this.person)
      } else {
        if (this.isSet) {
          const { tagTypes, tagIds, strange } = this.identity
          if (getAttr(this.person, 'areas', false)) {
            let filterTag = false
            const personTag = getAttr(this.person, 'tags[0]', false)
            if (personTag) {
              if (tagIds && tagIds.length > 0) {
                filterTag = tagIds.includes(personTag.id)
              } else {
                filterTag = tagTypes ? tagTypes.includes(personTag.type) : false
              }
            } else {
              filterTag = strange
            }
            const filterArea = this.person.areas.some((item: AnyObj) => {
              return this.areas.includes(item.id)
            })
            // 标签，标签类型，区域过滤
            if (filterTag && filterArea) {
              this.addPersonList(this.person, 'new')
            }
          }
        } else {
          if (!this.isRootAccount) {
            const filterArea = this.person.areas.filter((i: AnyObj) => i.id === this.root)
            if (filterArea.length === 0) return
          }
          this.addPersonList(this.person, 'new')
        }
      }
    }
  }

  destroyed() {
    window.removeEventListener('keyup', this.keyupFun)
    window.removeEventListener('resize', this.onWindowResize)
    clearInterval(this.setInterval)
    sessionStorage.removeItem('capacity')

    const arr = this.scene.children.filter((x: any) => x)
    arr.forEach((a: any) => {
      this.dispose(this.scene, a)
    })
    this.scene.remove()
    this.controls.dispose()
    this.renderer.domElement = null
    if (this.animateFrame) cancelAnimationFrame(this.animateFrame)
  }

  async mounted() {
    window.addEventListener('keyup', this.keyupFun)
    this.isShow = window.showVideo || window.showVideo === undefined ? true : false

    const a = window.innerWidth / window.innerHeight
    if (a > 16 / 9) {
      this.setVideoWidth = true
    } else {
      this.setVideoWidth = false
    }
    await this.getConfig()
    this.initBase()
    this.initPersonInfo()
    const renderImgs = document.getElementsByClassName('renderImg')
    for (const renderImg of renderImgs) {
      const rImg = renderImg as HTMLElement
      renderImg.addEventListener('click', () => {
        const id = rImg.dataset.id
        const msg = this.personList.find(l => l.id === id)
        if (id && msg) {
          this.showDialog = true
          const capture = getAttr(msg, 'capture', false)
          this.personInformation = {
            name: getAttr(msg, 'name', false),
            age: capture ? capture.attribute.age : msg.age,
            gender: capture ? capture.attribute.gender : msg.gender,
            imageUrl: capture ? capture.image : msg.imageUrl,
            registerImageUrl: getAttr(msg, 'image', false) ? msg.image : msg.registerImageUrl,
            position: getAttr(msg, 'position', false),
            tags: msg.tags ? msg.tags : []
          }
          setTimeout(() => {
            this.showDialog = false
            setTimeout(() => this.showMsg(), 1000)
          }, 7000)
        }
      })
    }
  }
  async updateDate() {
    await this.getConfig(false)
    this.CurPersonNum = 0
    this.personList = []
    this.msgList = []
    this.initImage()
    this.initPersonInfo()
  }
  keyupFun(e: any) {
    if (e.keyCode === 27) {
      this.$router.push({ path: '/' })
    }
  }
  settingCube() {
    EventBus.$emit('setDialog', true)
  }

  exitPerson() {
    // 退出
    if (!routeName) {
      this.$router.push('/')
    } else {
      this.$router.go(-1)
    }
  }

  refreshPerson() {
    // 刷新
    this.CurPersonNum = 0
    this.personList = []
    this.msgList = []
    this.initImage()
    const currentAccount = localStorage.getItem('currentAccount')
    if (currentAccount) {
      const areaClearTime = this.isRootAccount ? 'clearTime' : currentAccount + '-clearTime'
      localStorage.setItem(areaClearTime, new Date().toString())
    }
  }
  initImage() {
    const elements = document.getElementsByClassName('element')
    for (const element of elements) {
      fastdom.measure(() => {
        const node = element.childNodes[0] as HTMLElement
        fastdom.mutate(() => {
          if (node.tagName === 'IMG') {
            node.setAttribute('src', '/static/img/default-person.png')
            node.setAttribute('data-id', '')
          }
        })
      })
    }
  }

  showIcon() {
    this.isShowIcon = true
  }

  hiddenIcon() {
    this.isShowIcon = false
  }

  animateStop() {
    this.isMouse = true
  }

  animateStart() {
    this.isMouse = false
  }
  dispose(parent: any, child: any) {
    child.remove()
    parent.remove(child)
  }
  init() {
    const table: AnyObj[] = []
    for (let i = 0; i < this.personArray.length; i++) {
      table[i] = this.personArray[i]
      table[i].p_x = (i % 20) + 1
      table[i].p_y = Math.floor(i / 20) + 1
    }
    this.camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 10000)
    this.camera.position.z = 2800
    this.scene = new THREE.Scene()
    // table
    for (let i = 0; i < table.length; i++) {
      const element = document.createElement('div')
      element.className = 'element'
      element.style.backgroundImage = "url('/static/img/bg.png')"
      element.style.backgroundSize = '130px 130px'
      element.style.width = '130px'
      element.style.height = '130px'
      element.style.textAlign = 'center'
      const img =
        '<img class="renderImg" src="' +
        table[i].image +
        '" style="width:100px;height:100px;cursor:pointer;margin-top:15px;">'
      element.innerHTML = img
      let object: AnyObj = new THREE.CSS3DObject(element)
      object.position.x = Math.random() * 4000 - 2000
      object.position.y = Math.random() * 4000 - 2000
      object.position.z = Math.random() * 4000 - 2000
      this.scene.add(object)
      this.objects.push(object)
      // 表格需要坐标进行排序的
      object = new THREE.Object3D()
      object.position.x = table[i].p_x * 140 - 1330
      object.position.y = -(table[i].p_y * 180) + 990
      this.targets.table.push(object)
    }
    // sphere
    let vector = new THREE.Vector3()
    const spherical = new THREE.Spherical()
    for (let i = 0, l = this.objects.length; i < l; i++) {
      const phi = Math.acos(-1 + (2 * i) / l)
      const theta = Math.sqrt(l * Math.PI) * phi
      const object = new THREE.Object3D()
      spherical.set(800, phi, theta)
      object.position.setFromSpherical(spherical)
      vector.copy(object.position).multiplyScalar(2)
      object.lookAt(vector)
      this.targets.sphere.unshift(object)
    }
    // helix
    vector = new THREE.Vector3()
    const cylindrical = new THREE.Cylindrical()
    for (let i = 0, l = this.objects.length; i < l; i++) {
      const theta = i * 0.175 + Math.PI
      const y = -(i * 5) + 650
      const object = new THREE.Object3D()
      // 参数一 圈的大小 参数二 左右间距 参数三 上下间距
      cylindrical.set(900, theta, y)
      object.position.setFromCylindrical(cylindrical)
      vector.x = object.position.x * 2
      vector.y = object.position.y
      vector.z = object.position.z * 2
      object.lookAt(vector)
      this.targets.helix.push(object)
    }
    // grid
    for (let i = 0; i < this.objects.length; i++) {
      const object = new THREE.Object3D()
      object.position.x = (i % 5) * 400 - 800 // 400 图片的左右间距  800 x轴中心店
      object.position.y = -(Math.floor(i / 5) % 5) * 300 + 500 // 500 y轴中心店
      object.position.z = Math.floor(i / 25) * 200 - 1100 // 300调整 片间距 800z轴中心店
      this.targets.grid.push(object)
    }
    //渲染
    this.renderer = new THREE.CSS3DRenderer()
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.domElement.style.position = 'absolute'
    document.getElementById('container')?.appendChild(this.renderer.domElement)
    // 鼠标控制
    this.controls = new THREE.TrackballControls(this.camera, this.renderer.domElement)
    this.controls.rotateSpeed = 0.5
    this.controls.minDistance = 500
    this.controls.maxDistance = 6000
    // 自动更换
    let ini = 0
    this.setInter = window.setInterval(() => {
      ini = ini >= 3 ? 0 : ini
      ++ini
      switch (ini) {
        case 1:
          this.transform(this.targets.sphere, 1000)
          break
        case 2:
          this.transform(this.targets.helix, 1000)
          break
        case 3:
          this.transform(this.targets.grid, 1000)
          break
      }
    }, 15000)
    this.transform(this.targets.table, 2000)

    window.addEventListener('resize', this.onWindowResize, false)
  }

  transform(targets: AnyObj[], duration: number) {
    TWEEN.removeAll()
    for (let i = 0; i < this.objects.length; i++) {
      const object = this.objects[i]
      const target = targets[i]
      new TWEEN.Tween(object.position)
        .to(
          { x: target.position.x, y: target.position.y, z: target.position.z },
          Math.random() * duration + duration
        )
        .easing(TWEEN.Easing.Exponential.InOut)
        .start()
      new TWEEN.Tween(object.rotation)
        .to(
          { x: target.rotation.x, y: target.rotation.y, z: target.rotation.z },
          Math.random() * duration + duration
        )
        .easing(TWEEN.Easing.Exponential.InOut)
        .start()
    }
    new TWEEN.Tween(this).to({}, duration * 2).start()
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  animate() {
    // 让场景通过x轴或者y轴旋转  & z
    // scene.rotation.x += 0.011;

    if (this.isMouse) {
      // 鼠标放上时放慢速度
      this.scene.rotation.y += 0.004
    } else {
      this.scene.rotation.y += 0.008
    }
    this.animateFrame = requestAnimationFrame(this.animate)
    TWEEN.update()
    this.controls.update()
    // 渲染循环
    this.aRender()
  }

  aRender() {
    this.renderer.render(this.scene, this.camera)
  }

  initBase() {
    // 生成虚拟数据
    for (let i = 0; i < this.capacity; i++) {
      this.personArray.push({
        image: '/static/img/default-person.png'
      })
    }
    this.init()
    this.animate()
  }

  showMsg() {
    if (this.msgList.length > 0 && this.showDialog === false) {
      this.showDialog = true
      const msg = this.msgList[0]

      const capture = getAttr(msg, 'capture', false)
      this.personInformation = {
        name: getAttr(msg, 'name', false),
        age: capture ? capture.attribute.age : msg.age,
        gender: capture ? capture.attribute.gender : msg.gender,
        imageUrl: capture ? capture.image : msg.imageUrl,
        registerImageUrl: getAttr(msg, 'image', false) ? msg.image : msg.registerImageUrl,
        position: getAttr(msg, 'position', false),
        tags: msg.tags ? msg.tags : []
      }
      this.msgList.shift()
      setTimeout(() => {
        this.showDialog = false
        setTimeout(() => this.showMsg(), 1000)
      }, 7000)
    }
  }

  showImg(person: AnyObj) {
    const capture = getAttr(person, 'capture', false)

    if (capture && capture.image) {
      return capture.image
    } else if (person.imageUrl) {
      return person.imageUrl
    } else {
      return '/static/img/default-person.png'
    }
  }

  addImg(obj: AnyObj, type: string) {
    const element = document.getElementsByClassName('element')[this.CurPersonNum]
    if (element) {
      const img = element.getElementsByTagName('img')[0]
      img.setAttribute('src', this.showImg(obj))
      img.setAttribute('data-id', obj.id)
      ++this.CurPersonNum
      this.personList.push(obj)
      if (type === 'new') {
        this.msgList.push(this.person)
        if (window.showMsg || window.showMsg === undefined) {
          this.showMsg()
        }
        this.isShow = window.showVideo || window.showVideo === undefined ? true : false
      }
    }
  }
  compare(name: string) {
    return function (object1: AnyObj, object2: AnyObj) {
      const value1 = object1[name]
      const value2 = object2[name]
      if (value2 < value1) {
        return 1
      } else if (value2 > value1) {
        return -1
      } else {
        return 0
      }
    }
  }
  async initPersonInfo() {
    const account = localStorage.getItem('lastAccount')
    const currentAccount = localStorage.getItem('currentAccount')
    if (account && account !== currentAccount) {
      // 切换账号时清空清除时间
      localStorage.removeItem('clearTime')
    }
    let clearTime
    if (currentAccount) {
      localStorage.setItem('lastAccount', currentAccount)
      const areaClearTime = this.isRootAccount ? 'clearTime' : currentAccount + '-clearTime'
      clearTime = localStorage.getItem(areaClearTime)
    }
    const today = moment(new Date()).startOf('day')
    let personList: any[] = []
    if (clearTime && moment(new Date(clearTime)).valueOf() >= today.valueOf()) {
      // 今天有清除状态
      const clearDate = moment(new Date(clearTime)).toISOString()
      const endTime = moment(new Date()).toISOString()
      let queryList = await this.getTripsList(clearDate, endTime)
      const start = today.toISOString()
      const resp = await this.getTripsList(start, clearDate, 'all') // 应该请求所有的数据
      if (resp && queryList) {
        const arr = resp.filter(obj => {
          // 被清除的人，再次出现
          const date = moment(new Date(obj.lastAppearTime)).toISOString()
          return date > clearDate
        })
        if (arr.length > 0) {
          if (queryList.length === 0) {
            queryList = arr
          } else {
            arr.forEach(item => {
              item.firstAppearTime = item.lastAppearTime
            })
            queryList = queryList.concat(arr)
            queryList.sort(this.compare('firstAppearTime'))
          }
        }
      }
      personList = personList.concat(queryList)
    } else {
      personList = personList.concat(await this.getTodayTrips())
    }
    personList = this.unique(personList, 'personId')
    if (personList.length > 0) {
      personList.forEach(item => {
        this.addPersonList(item, 'old')
      })
    }
  }
  getTodayTrips() {
    const startTime = moment(new Date()).startOf('day').toISOString()
    const endTime = moment(new Date()).toISOString()
    return this.getTripsList(startTime, endTime)
  }

  private async getTripsList(startTime: any, endTime: any, flag = '') {
    if (!this.isSet) {
      this.areas = [this.root]
    } else {
      const { tagTypes, tagIds, strange } = this.identity
      if (
        this.areas.length === 0 ||
        (tagTypes && tagTypes.length === 0 && tagIds && tagIds.length === 0 && !strange)
      ) {
        return []
      }
    }
    const offset = 0
    const par = {
      startTime: startTime,
      endTime: endTime,
      limit: 100,
      offset: offset,
      identity: this.identity
    }

    let oldPersonList: any[] = []

    let index = 0
    const self = this
    const getList = async () => {
      if (index >= self.areas.length) return
      par.offset = 0
      const { data } = await self.$api.base.getTripsList(self.areas[index], par)
      if (data.code == 0) {
        oldPersonList = oldPersonList.concat(data.data)
        oldPersonList = this.unique(oldPersonList, 'personId')
        if (flag === 'all') {
          if (data.count > 100) {
            const num = Math.ceil(data.count / 100) - 1
            for (let i = 0; i < num; i++) {
              par.offset = 100 * (i + 1)
              const res = await self.$api.base.getTripsList(self.areas[index], par)
              oldPersonList = oldPersonList.concat(data.data)
              oldPersonList = this.unique(oldPersonList, 'personId')
            }
          }
          index++
          await getList()
        } else {
          if (data.count > 100 && self.capacity - oldPersonList.length > 0) {
            const count =
              data.count - 100 + oldPersonList.length > self.capacity
                ? self.capacity - oldPersonList.length + 100
                : data.count
            const num = Math.ceil(count / 100) - 1
            for (let i = 0; i < num; i++) {
              par.offset = 100 * (i + 1)
              const res = await self.$api.base.getTripsList(self.areas[index], par)
              oldPersonList = oldPersonList.concat(data.data)
            }
          }
          oldPersonList = this.unique(oldPersonList, 'personId')
          // 如果count值小于this.capacity再请求下一个area
          if (oldPersonList.length < self.capacity) {
            index++
            await getList()
          }
        }
      }
    }
    await getList()
    if (oldPersonList.length > 0) oldPersonList.sort(this.compare('firstAppearTime'))
    oldPersonList.map(item => {
      return (item.id = item.personId)
    })
    return oldPersonList
  }
  unique(arr: any[], val: string) {
    const res = new Map()
    return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
  }
  async getConfig(isCompare = true) {
    const { data } = await this.$api.base.getConfigCube()
    if (data && data.data) {
      this.isSet = true
      const resp = data.data
      this.configData = { ...resp }
      this.left = resp.left
      this.right = resp.right
      this.title = resp.title
      this.subtitle = resp.subtitle
      if (resp.identity) {
        const { tagIds, tagTypes } = resp.identity
        if (!tagIds && !tagTypes) {
          this.identity = { tagIds: [], tagTypes: ['vip', 'white', 'black'], strange: true }
        } else {
          this.identity = resp.identity
        }
      }
      this.logo = resp.logo
      this.wallBg = 'url(' + resp.backgroundUrl + ')'
      this.areas = resp.areaIds || [this.root]
      this.capacity = resp.capacity === null ? 300 : resp.capacity
    } else {
      this.isSet = false
    }
    if (isCompare) {
      await this.compareConfig()
    }
  }

  async compareConfig() {
    const { areas, identity } = this
    let usable = true
    if (identity.tagIds) {
      await store.dispatch(actionTypes.FETCH_TAGS)
      usable = compareTagOrArea('tag', identity.tagIds)
    }

    if (areas && usable) {
      await store.dispatch(actionTypes.FETCH_BASE_AREAS)
      usable = compareTagOrArea('area', areas)
    }

    if (usable == false) {
      await this.resetConfig()
    }
  }

  async resetConfig() {
    const params: AnyObj = {
      ..._.omit(this.configData, ['createdAt', 'updatedAt', 'backgroundUrl']),
      areaIds: [this.root],
      identity: {}
    }
    await this.$api.base.submitConfigCube(params)
    await this.updateDate()
  }

  addPersonList(item: any, type: string) {
    if (this.CurPersonNum >= this.personArray.length) {
      this.CurPersonNum = 0
    }
    if (this.personList.length >= this.personArray.length) {
      this.personList.shift()
    }
    this.addImg(item, type)
  }
}
